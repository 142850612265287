'use strict'

###*
 # @ngdoc service
 # @name lpaDispatching.factory:SavedMapPreferences

 # @description Returns a restangular factory for managing settings and stuff

###
angular
  .module 'lpaDispatching'
  .factory 'SavedMapPreferences', [
    'AppSettings',
    '$log',
    'RestUtils'
    (AppSettings, $log, RestUtils) ->

      SavedMapPreferencesBase = {}

      SavedMapPreferencesBase.getPreferences = () ->
        RestUtils.getFullList(AppSettings, {sort: "title,ASC"})
        .then (results) ->
          return results

      SavedMapPreferencesBase

  ]
